import React from 'react';

export const USER_LOGGED_IN = 'authsContext/loggedIn';
export const USER_LOGGED_OUT = 'authsContext/loggedOut'
export const SET_USER_DATA = 'authsContext/setUserData';

const AuthsStateContext = React.createContext(undefined);
const AuthsDispatchContext = React.createContext(undefined);

const initialState = {
    userIsAuthenticated: false,
    userData: {},
}

const authsReducer = (state, action) => {
    switch(action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                userIsAuthenticated: true
            }
        case USER_LOGGED_OUT:
            return {
                ...state,
                userIsAuthenticated: false,
                userData: initialState.userData
            }
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.userData
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const AuthsProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(authsReducer, {...initialState}, undefined);

    return (
        <AuthsStateContext.Provider value={state}>
            <AuthsDispatchContext.Provider value={dispatch}>
                {children}
            </AuthsDispatchContext.Provider>
        </AuthsStateContext.Provider>
    )
}

export const useAuthsState = () => {
    const context = React.useContext(AuthsStateContext)
    if(undefined === context) {
        throw new Error('useSearchResultState must be used within a AuthsProvider');
    } else {
        return context
    }
}

export const useAuthsDispatch = () => {
    const context = React.useContext(AuthsDispatchContext);
    if(undefined === context) {
        throw new Error('useSearchResultDetailsDispatch must be used within a AuthsProvider');
    } else {
        return context;
    }
}

