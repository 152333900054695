import React from 'react';
import {Container, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {WithMuseumSideSheet} from '@ekultur/museum-filter';

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        overflow: "hidden"
    },
    container: {
        height: "100vh",
        overflowY: "auto"
    }
}))

export const Content = ({children}) => {
    const classes = useStyles();
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div className={classes.root}>
            <WithMuseumSideSheet forceMobile={mdDown}>
                <Container
                    maxWidth={false}
                    className={classes.container}
                >
                    <Toolbar />
                    {children}
                </Container>
            </WithMuseumSideSheet>
        </div>
    )
}