import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from "@mui/material/Backdrop";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        outline: 'none',
        [theme.breakpoints.down('md')]: {
            height: "100%",
            width: "100%"
        },
        [theme.breakpoints.up('sm')]: {
            width: props => props.isFullscreen ? "100%" : "70%",
        }
    },
    paper: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            height: "100%"
        },
        backgroundColor: grey[100]
    },
}));

export const EkulturModal = ({open, onHide, children}) => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={onHide}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.content}>
                <Grow in={open}>
                    <Paper className={classes.paper}>
                        {children}
                    </Paper>
                </Grow>
            </div>
        </Modal>
    )
}