import React, {useState} from 'react';
import {useMetricsDM} from "./useMetricsDM";
import makeStyles from '@mui/styles/makeStyles';
import {Paper, Tab, Tabs, useTheme} from "@mui/material";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {MetricsTooltip} from "../overview/MetricsTooltip";
import {parseDate, parseDimension} from "../google-analytics/googleAnalyticsParser";
import {TabLabel} from "../overview/TabLabel";
import {useAppTranslation} from "../app/appContext";
import {WithProgressOverlay} from "../app/WithProgressOverlay";

const useStyles = makeStyles(theme => ({
    root: {
    },
    container: {
        padding: theme.spacing(2),
    },
    tabs: {
        marginBottom: theme.spacing(2),
    },
    tabPanel: {
      padding: theme.spacing(2)
    },
    tabIndicator: {
        top: 0
    }
}))

export const MetricsDM = () => {
    const classes = useStyles();
    const {palette} = useTheme()
    const [selectedTab, setSelectedTab] = useState(0);
    const t = useAppTranslation();
    const {
        loading,
        dimension,
        rows,
        maximums,
        dateRanges,
        totals
    } = useMetricsDM();

    const data = rows.map(row => ({
        name: row.dimensions[0],
        thisPeriod: row.metrics[0].values[selectedTab],
        previousPeriod: row.metrics.length > 1 ? row.metrics[1].values[selectedTab] : 0
    }))

    const yMax = maximums.length ? Math.max(maximums[0].values[selectedTab], (maximums[1]?.values[selectedTab] || 0)) : 1000

    const period = {
        startDate: dateRanges.length ? parseDate(dateRanges[0].startDate) : "",
        endDate: dateRanges.length ? parseDate(dateRanges[0].endDate) : ""
    }
    const previousPeriod = () =>  {
        if(dateRanges.length > 1) {
            return {
                startDate: dateRanges.length > 1 ? parseDate(dateRanges[1].startDate) : "",
                endDate: dateRanges.length > 1 ? parseDate(dateRanges[1].endDate) : ""
            }
        } else {
            return null
        }
    }

    const handleTabChange = (event, newValue) => setSelectedTab(newValue)

    return (
        <WithProgressOverlay loading={loading}>
            <Paper className={classes.root}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="metrics tabs"
                    indicatorColor={"primary"}
                    className={classes.tabs}
                    classes={{
                        indicator: classes.tabIndicator
                    }}
                >
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('uniquePageViews', "Unike sidevisninger")}
                                totals={totals.length ? totals[0].values[0] : 0}
                                previousTotals={totals.length > 1 ? totals[1].values[0] : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('users', "Brukere")}
                                totals={totals.length ? totals[0].values[1] : 0}
                                previousTotals={totals.length > 1? totals[1].values[1] : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('visits', "Besøk")}
                                totals={totals.length ? totals[0].values[2] : 0}
                                previousTotals={totals.length > 1? totals[1].values[2] : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                </Tabs>
                <div className={classes.container}>
                    <ResponsiveContainer
                        width={"97%"}
                        height={300}
                        debounce={1}
                    >
                        <LineChart
                            // width={500}
                            // height={300}
                            data={data}
                        >
                            <CartesianGrid vertical={false}/>
                            <XAxis
                                dataKey="name"
                                tickFormatter={tickItem => parseDimension(period, dimension, tickItem)}
                                axisLine={false}
                                interval={"preserveStartEnd"}
                                padding={{right: 40}}
                            />
                            <YAxis
                                domain={[0, yMax]}
                                orientation={"right"}
                                axisLine={false}
                                tickLine={false}
                                mirror={true}
                            />
                            <Tooltip
                                content={
                                    <MetricsTooltip
                                        dimension={dimension}
                                        period={period}
                                        previousPeriod={previousPeriod()}
                                    />
                                }
                            />
                            <Line
                                type="monotone"
                                dataKey="thisPeriod"
                                stroke={palette.primary.main}
                                activeDot={{r: 8}}
                                dot={false}
                            />
                            <Line
                                type="monotone"
                                dataKey="previousPeriod"
                                stroke={palette.primary.light}
                                strokeDasharray="3 4 5 2"
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        </WithProgressOverlay>
    )
};
