import React, {useEffect, useState} from "react";
import {themeOptions} from "../app/Theme";
import {login, logout} from "@ekultur/authentication";
import {useLocation} from 'react-router-dom';
import {useAuthsDispatch, useAuthsState, USER_LOGGED_OUT} from "../auths/authsContext";
import {MENU_OPENED, RENDERED, useHeaderDispatch} from "./headerContext";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    hamburger: {
        [theme.breakpoints.up('md')]: {
            '&.header-MuiButtonBase-root': {
                display: "none"
            }
        },
        margin: 0
    },
}))

export const Header = () => {
    const classes = useStyles();
    const [scripts, setScripts] = useState(null);
    const host = 'https://beta-header.ekultur.org/'
    const containerId = 'header--container'
    const scriptId = 'micro-frontend-script-header';

    const dispatch = useHeaderDispatch();
    const location = useLocation();
    const {userIsAuthenticated} = useAuthsState();
    const dispatchAuths = useAuthsDispatch();

    useEffect(() => {
        if (0 === scripts) {
            render()
        } else {
            fetch(`${host}asset-manifest.json`)
                .then(res => res.json())
                .then(manifest => {
                    const entrypoints = manifest['entrypoints']
                    setScripts(entrypoints.length)
                    entrypoints.forEach(entrypoint => {
                        const scriptName = entrypoint.split('/').slice(-1);
                        const script = document.createElement('script');
                        script.id = `${scriptId}-${scriptName}`;
                        script.src = `${host}${entrypoint}`
                        script.onload = () => {
                            setScripts(d => d - 1);
                        }
                        document.head.appendChild(script)
                    });
                });
        }
        return () => window['unmountHeader'](containerId);
    }, [])

    useEffect(() => {
        if (0 === scripts) {
            render()
        }
    }, [scripts, userIsAuthenticated])

    const loginHandler = () => {
        const prevPath = location.state?.previousPath ? location.state.previousPath : "/"
        login({
            locationPostLogin: prevPath,
            loginHost: window._env_.REACT_APP_LOGIN_HOST
        })
    }

    const logoutHandler = () => {
        logout({
            onLogout: () => dispatchAuths({type: USER_LOGGED_OUT})
        })
    }

    const render = () => {
        window['renderHeader']({
            containerId: containerId,
            appId: window._env_.REACT_APP_ID,
            loggedIn: userIsAuthenticated,
            mobileOpenHandler: () => dispatch({type: MENU_OPENED}),
            logoutHandler: () => logoutHandler(),
            loginHandler: loginHandler,
            themeOptions: themeOptions,
            preselectMuseumId: null,
            mobileOpen: false,
            onRender: () => dispatch({type: RENDERED}),
            className: classes,
            version: window._env_.REACT_APP_VERSION,
        })
    };

    return <div id={containerId} />
}
