import {
    format,
    subDays,
    setHours,
    addDays,
    subYears,
    startOfYear,
    endOfYear,
    addMonths,
    differenceInQuarters,
    addWeeks,
    eachQuarterOfInterval
} from "date-fns";
import svLocale from "date-fns/locale/sv";
import nbLocale from "date-fns/locale/nb";
import nnLocale from "date-fns/locale/nn";
import enLocale from "date-fns/locale/en-US";

export const getLocale = () => {
    if('sv' === navigator.language){
        return svLocale;
    } else if('no' === navigator.language || 'nb' === navigator.language){
        return nbLocale;
    } else if('nn' === navigator.language){
        return nnLocale;
    } else {
        return enLocale;
    }
}

export const daysAgo = subtractDays => subDays(new Date(), subtractDays)

export const yearsAgo = subtractYears => subYears(new Date(), subtractYears)

export const today = () => format(new Date(), 'PPp')

export const withHours = (date, hours) => format(setHours(new Date(date), parseInt(hours)), 'PPp', {locale: getLocale()})

export const onlyHours = hours => format(setHours(new Date(), parseInt(hours)), 'HH:00')

export const withAddedDays = (date, days) => format(addDays(new Date(date), parseInt(days)), 'dd MMM.', {locale: getLocale()})

export const withAddedMonths = (date, months) => format(addMonths(new Date(date), parseInt(months)), 'MMM. yy', {locale: getLocale()})

export const withAddedWeeks = (date, weeks) => format(addWeeks(new Date(date), parseInt(weeks)), 'w. yyyy', {locale: getLocale()})

export const dateAsGoogleFormat = date => format(date, "yyyy-MM-dd")

export const yearsAgoAsGoogleFormat = (date, years) => format(subYears(date, parseInt(years)), "yyyy-MM-dd")

export const startOfTheYear = date => {
    const _date = date ? date : new Date();
    return startOfYear(_date);
}

export const endOfTheYear = (date=null) => {
    const _date = date ? date : new Date();
    return endOfYear(_date)
}

export const startOfTheQuarter = ({quarter, date=null}) => {
    const quarters = getAllQuarters({
        fromDate: startOfTheYear(date),
        toDate: endOfTheYear(date)
    })

    return quarters[quarter - 1]
}

export const endOfTheQuarter = ({quarter, date=null}) => {
    const quarters = getAllQuarters({
        fromDate: startOfTheYear(date),
        toDate: endOfTheYear(date)
    })

    if(quarter === 4) {
        return endOfTheYear(date)
    } else {
        return subDays(quarters[quarter], 1)
    }

}

export const countQuarters = (toDate, fromDate) => differenceInQuarters(toDate, fromDate)

const getAllQuarters = ({fromDate, toDate}) => eachQuarterOfInterval({
    start: fromDate ? fromDate : new Date(),
    end: toDate ? toDate : new Date()
})

export const asGoogleFormat = date => format(date, 'yyyy-MM-dd');

export const rangeAsGoogleFormat = dates => dates.map(asGoogleFormat).join(',')

export const dateLocalized = date => format(new Date(date), "PPP", {locale: getLocale()})

export const formatYear = date => format(new Date(date), 'yyyy', {locale: getLocale()})