import React from 'react';
import {adaptV4Theme} from "@mui/material/styles";
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import ThemeProvider from "@mui/styles/ThemeProvider";
import {Box, createTheme, StyledEngineProvider} from "@mui/material";
import StylesProvider from '@mui/styles/StylesProvider';
import {grey} from "@mui/material/colors";

const generateClassName = createGenerateClassName({
    seed: 'ekulturStatistics'
});

export const Theme = ({children}) => {
    const appTheme = createAppTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <StylesProvider generateClassName={generateClassName}>
                    <Box
                        sx={{
                            zIndex: 1,
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        {children}
                    </Box>
                </StylesProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export const themeOptions = {
    palette: {
        primary: {
            main: '#3154ea',
            light: '#8aa7f7',
            dark: '#24439f'
        },
        background: {
            default: grey[100]
        }
    },
    props: {
        MuiTextField: {
            variant: 'filled',
            color: "primary"
        },
        MuiFormLabel: {
            color: "primary"
        },
        MuiSelect: {
            variant: 'filled',
            color: "primary"
        },
    }
};

const createAppTheme = () => createTheme(adaptV4Theme(themeOptions));