import {useMatch} from "react-router";
import {PageViews} from "./PageViews";

export const PageViewsRoute = () => {
    const match = useMatch('/page-views')

    if(match) {
        return (
            <PageViews />
        )
    } else {
        return null
    }

}