import {useState} from 'react';
import {useFilteredDmOwners} from "../overview/digitaltmuseum/useFilteredDmOwners";
import {usePeriodState} from "../date/periodContext";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {kitFetch} from "@ekultur/fetch";
import {useHasFilterModifications} from "../museum/useHasFilterModifications";
import {stringify} from "../querystring/querystring";

export const useUsersByObjectTypeDM = () => {
    const url = '/dm-admin-api/v1/statistics/playground/reports/';
    const [response, setResponse] = useState({
        loading: false,
        data: [],
        dataMin: 0,
        dataMax: 100
    })

    const {
        endDate,
        startDate,
        id
    } = usePeriodState();

    const owners = useFilteredDmOwners().map(owner => owner.id).join(',');
    const hasFilterModifications = useHasFilterModifications();

    useDeepCompareEffectNoCheck(() => {
        if(owners && !hasFilterModifications) {
            setResponse(r => ({
                ...r,
                loading: true
            }))
            const parameters = {
                reportType: 0,
                endDate: endDate,
                metrics: 'users',
                dimensions: `dimension4`,
                orderBy: 'users',
                sort: 'descending',
                owner: owners,
                countryId: 'no',
                startDate: startDate,
                id: id,
                rows: 6,
                emptyRows: true
            };

            kitFetch(`${url}?${stringify(parameters)}`)
                .then(json => setResponse(({
                    loading: false,
                    data: json.reports[0].data.rows.map(row => ({
                        name: row.dimensions[0],
                        users: row.metrics[0].values[0]

                    })),
                    dataMin: 0,
                    dataMax: parseInt(json.reports[0].data.maximums[0].values[0])
                })))
                .catch(error => {
                    console.error(error)
                    setResponse(r => ({
                        ...r,
                        loading: false
                    }))
                });
        }
    }, [owners, endDate, startDate, id, hasFilterModifications])

    return response;
};