import React, {useEffect} from 'react';
import {refreshToken} from "@ekultur/authentication";
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext";
import {useLocation} from 'react-router-dom';

export const WithAuthentication = ({children}) => {
    const location = useLocation()
    const dispatch = useAuthsDispatch();

    useEffect(() => {
        if(!location.pathname.startsWith('/oauth2/callback')) {
            refreshToken({
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: () => dispatch({type: USER_LOGGED_IN})
            })
        }
    }, [location.pathname])

    return (
        <>
            {children}
        </>
    )
}