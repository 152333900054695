import React from 'react';
import {Badge, Box, IconButton, Paper, Toolbar} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MuseumIcon from "@mui/icons-material/Museum";
import {OPEN, useMuseumFilterDispatch, useMuseumFilterState} from "@ekultur/museum-filter";
import RefreshIcon from '@mui/icons-material/Refresh';
import {FILTER_BY_MUSEUM_IDS, useMyMuseumsDispatch} from "../museum/myMuseumsContext";
import {DateSelector} from "../date/DateSelector";
import {useHasFilterModifications} from "../museum/useHasFilterModifications";
import {FilterWarning} from "../museum/FilterWarning";

const useStyles = makeStyles(theme => ({
    root: {

    },
    break: {
        flexBasis: "100%",
        height: 0,
        marginBottom: theme.spacing(2)
    },
    toolbar: {
        padding: theme.spacing(2),
        flexWrap: "wrap",
        marginBottom: theme.spacing(1)
    },
    museumFilter: {
        width: "100%"
    }
}))

export const Header = ({showMuseumFilter = false, showDateSelector=false, children}) => {
    const classes = useStyles();
    const museumFilterDispatch = useMuseumFilterDispatch();
    const myMuseumsDispatch = useMyMuseumsDispatch()
    const {checked} = useMuseumFilterState();
    const refreshEnabled = useHasFilterModifications();

    const showFilterHandler = () => museumFilterDispatch({type: OPEN});

    const updateMuseumsFilterHandler = () => {
        myMuseumsDispatch({
            type: FILTER_BY_MUSEUM_IDS,
            museumIds: checked
        })
    }

    const museumFilter = showMuseumFilter
        ? (
            <IconButton onClick={showFilterHandler} size="large">
                <Badge
                    color={"primary"}
                    badgeContent={checked.length}
                >
                    <MuseumIcon />
                </Badge>
            </IconButton>
        )
        : null

    const dateSelector = showDateSelector ? <DateSelector /> : null;

    const refreshButton = showMuseumFilter || showDateSelector
        ? (
            <IconButton
                onClick={updateMuseumsFilterHandler}
                disabled={!refreshEnabled}
                size="large">
                <RefreshIcon />
            </IconButton>
        ) : null

    return (
        <Paper className={classes.root}>
            <Toolbar className={classes.toolbar}>
                {children}
                {dateSelector}
                <Box flexGrow={1} />
                {refreshButton}
                {museumFilter}
            </Toolbar>
            <FilterWarning />
        </Paper>
    );
};