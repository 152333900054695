import React from 'react';
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";

export const ProgressIndicator = ({show=true}) => {

    if(show) {
        return (
            <div>
                <Fade
                    in={true}
                    style={{
                        transitionDelay: '800ms'
                    }}
                    unmountOnExit={true}
                >
                    <LinearProgress />
                </Fade>
            </div>
        )
    } else {
        return null;
    }
};