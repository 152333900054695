import React, {useEffect, useRef, useState} from 'react';
import {useHasFilterModifications} from "./useHasFilterModifications";
import {usePeriodState} from "../date/periodContext";
import {EkulturModal} from "../app/EkulturModal";
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Toolbar,
    Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useAppState, useAppTranslation} from "../app/appContext";
import {FILTER_BY_MUSEUM_IDS, useMyMuseumsDispatch, useMyMuseumsState} from "./myMuseumsContext";
import {SET_CHECKED, useMuseumFilterDispatch, useMuseumFilterState} from "@ekultur/museum-filter";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles(theme => ({
    root: {
        height: "80vh",
        width: "100%",
        margin: theme.spacing(2)
    },
    header: {
        width: "100%"
    },
    toolbar: {
        '& Button': {
            marginLeft: theme.spacing(2)
        }
    },
    modifications: {
        height: props => `calc(100% - ${props.subtractFromHeight}px)`,
        overflowY: "auto",
    }
}))

export const FilterWarning = () => {
    const [headerHeight, setHeaderHeight] = useState(120);
    const [footerHeight, setFooterHeight] = useState(64);
    const [showWarning, setShowWarning] = useState(false);
    const hasFilterModifications = useHasFilterModifications();
    const {checked} = useMuseumFilterState();
    const {museumIdsFilter} = useMyMuseumsState();
    const {museums} = useAppState();
    const myMuseumsDispatch = useMyMuseumsDispatch();
    const museumFilterDispatch = useMuseumFilterDispatch();
    const headerRef = useRef();
    const footerRef = useRef();

    const t = useAppTranslation();
    const classes = useStyles({
        subtractFromHeight: headerHeight + footerHeight
    });


    useEffect(() => {
        if(headerRef.current) {
            setHeaderHeight(headerRef.current.clientHeight)
        }
    }, [setHeaderHeight])

    useEffect(() => {
        if(footerRef.current) {
            setFooterHeight(footerRef.current.clientHeight)
        }
    }, [setFooterHeight])

    const {
        startDate,
        endDate
    } = usePeriodState();

    const handleFilterChanged = () => {
        if(hasFilterModifications) {
            setShowWarning(true);
        }
    }

    const cancelHandler = () => {
        museumFilterDispatch({
            type: SET_CHECKED,
            checked: museumIdsFilter
        })
        setShowWarning(false);
    }

    const confirmHandler = () => {
        myMuseumsDispatch({
            type: FILTER_BY_MUSEUM_IDS,
            museumIds: checked
        })
        setShowWarning(false);
    }

    useEffect(() => {
        handleFilterChanged();
    }, [startDate, endDate])


    const removedMuseums =() => {
        const oldMuseums = museumIdsFilter.filter(m => !checked.includes(m));

        if(oldMuseums.length) {
            return (
                <List
                    subheader={
                        <ListSubheader>
                            {t('filterWarningRemovedMuseums', "{{count}} tatt vekk", {count: oldMuseums.length})}
                        </ListSubheader>
                    }
                >
                    {oldMuseums.map(id => (
                        <ListItem key={id}>
                            <ListItemIcon>
                                <RemoveIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={museums.find(museum => museum.id === id).name}
                            />
                        </ListItem>
                    ))}
                </List>
            )
        } else {
            return null;
        }
    }

    const addedMuseums = () => {
        const newMuseums =checked.filter(c => !museumIdsFilter.includes(c));
        if(newMuseums.length) {
            return (
                <List
                    subheader={
                        <ListSubheader>
                            {t('filterWarningAddedMuseums', "{{count}} lagt til", {count: newMuseums.length})}
                        </ListSubheader>
                    }
                >
                    {newMuseums.map(id => (
                        <ListItem key={id}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={museums.find(museum => museum.id === id).name}
                            />
                        </ListItem>
                    ))}
                </List>
            )
        } else {
            return null;
        }
    }

    return (
        <EkulturModal
            open={showWarning}
            onHide={null}
        >
            <div
                className={classes.root}
            >
                <div ref={headerRef}>
                    <Typography variant={"h6"} component={"h2"}>
                        {t('filterWarningHeader', "Du har ubekreftede endringer i museumsfilteret")}
                    </Typography>
                    <Typography>
                        {t('filterWarningQuestion', "Ønsker du å bekrefte eller angre endringene i museumsfilteret?")}
                    </Typography>
                </div>
                <div className={classes.modifications}>
                    {addedMuseums()}
                    {removedMuseums()}
                </div>
                <div ref={footerRef}>
                    <Toolbar className={classes.toolbar} flexGrow={12}>
                        <Box flexGrow={1} />
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={cancelHandler}
                        >
                            {t('filterWarningAnswerUndo', "Angre")}
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={confirmHandler}
                        >
                            {t('filterWarningAnswerApply', "Bekrefte")}
                        </Button>
                    </Toolbar>
                </div>
            </div>
        </EkulturModal>
    )
}