import React from 'react';
import { CircularProgress, Fade } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    progress: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: theme.zIndex.drawer - 1,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    content: {
        opacity: props => props.loading ? 0.5 : 1
    }
}))

export const WithProgressOverlay = ({loading, children}) => {
    const classes = useStyles({
        loading: loading
    });

    const progress = loading
        ? (
            <div className={classes.progress}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: '400ms'
                    }}
                    unmountOnExit={true}
                >
                    <CircularProgress/>
                </Fade>
            </div>
        )
        : null;

    return (
        <div className={classes.root}>
            {progress}
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}