import React from 'react';
import {WorldMap} from '@ekultur/svgmap';
import {Paper, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useAppTranslation} from "../app/appContext";
import {CountriesDMBar} from "./CountriesDMBar";
import {WithProgressOverlay} from "../app/WithProgressOverlay";
import {useCountries} from "./useCountries";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        overflow: "hidden",
    },
    map: {
        maxWidth: 400,
        height: 300
    },
    content: {
        minHeight: 500,
    }
}))

export const CountriesDM = () => {
    const classes = useStyles();
    const t = useAppTranslation();

    const {
        data,
        loading
    } = useCountries();


    const top5 = data.sort((a, b) => b.value - a.value).slice(0, 5)

    return (
        <WithProgressOverlay loading={loading}>
            <Paper className={classes.root}>
                <Typography variant="h6" align='center'>
                    {t('visitsByCountry', "Besøk etter land")}
                </Typography>
                <div className={classes.content}>
                    <div className={classes.map}>
                        <WorldMap data={data} lowRes={true}/>
                    </div>
                    <CountriesDMBar
                        data={top5}
                    />
                </div>
            </Paper>
        </WithProgressOverlay>
    )

};