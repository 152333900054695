import {useMatch} from "react-router";
import {Overview} from "./Overview";

export const OverviewRoute = () => {
    const match = useMatch('/')

    if(match) {
        return (
            <Overview />
        )
    } else {
        return null
    }

}