import {PageViewsTable} from "./PageViewsTable";
import React from "react";
import {Page} from "../layout/Page";
import {Header} from "../layout/Header";
import {Typography} from "@mui/material";
import {useAppTranslation} from "../app/appContext";

export const PageViews = () => {
    const t = useAppTranslation();
    return (
        <Page >
            <Header showMuseumFilter={true} showDateSelector={true}>
                <Typography variant={"h6"} component={"h1"}>
                    {t('pageViews', 'Sidevisninger')}
                </Typography>
            </Header>
            <PageViewsTable />
        </Page>
    )
};