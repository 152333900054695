import {useDispatch, useSelector} from "react-redux";
import {useFilteredDmOwners} from "../overview/digitaltmuseum/useFilteredDmOwners";
import {useEffect} from "react";
import {getPublishedObjects} from "./publishedObjectsAction";


export const usePublishedObjects = () => {
    const dispatch = useDispatch();
    const {reports} = useSelector(state => state.publishedObjectsDm);

    const owners = useFilteredDmOwners().map(owner => owner.id).join(',');
    const report = reports[owners];

    useEffect(() => {
        if(owners && noReportOrRetryFailed(report)) {
            dispatch(getPublishedObjects(owners))
        }
    }, [dispatch, owners, report]);


    if(report) {
        return report
    } else {
        return {rows: [], published: 0, notPublished: 0, dataMin: 0, dataMax: 0}
    }

};

const noReportOrRetryFailed = report => !report || (report.failed && 1000 < (new Date().getTime() - report.failTime));