import React from 'react';
import {useUsersByObjectTypeDM} from "./useUsersByObjectTypeDM";
import {useTranslation} from "react-i18next";
import {Paper, useTheme} from "@mui/material";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import {useAppTranslation} from "../app/appContext";
import {WithProgressOverlay} from "../app/WithProgressOverlay";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    }
}))

export const UsersByObjectTypeDM = () => {
    const {data, dataMin, dataMax, loading} = useUsersByObjectTypeDM();
    const {t, ready} = useTranslation('dm.objectTypes', {useSuspense: false});
    const {palette} = useTheme();
    const classes = useStyles();
    const appT = useAppTranslation()
    const translatedData = data.map(row => ({
        ...row,
        name: t(row.name.toLowerCase())
    }));

    return (
        <WithProgressOverlay loading={!ready || loading}>
            <Paper className={classes.root}>
                <Typography variant="h6" align='center'>
                    {appT('visitsByObjectType', "Besøk per objekttype")}
                </Typography>
                <ResponsiveContainer
                    width='97%'
                    height={500}
                    id="usersByObjectResponsive"
                >
                    <BarChart layout="vertical"
                              data={translatedData}
                              margin={{top: 10,  left: 30}}
                    >
                        <CartesianGrid stroke='#f5f5f5'/>
                        <XAxis type="number" domain={[dataMin, dataMax]} />
                        <YAxis dataKey="name" type="category"/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey='users' name={appT('users', 'Brukere')} barSize={20} fill={palette.primary.main}/>
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </WithProgressOverlay>
    )

};
