import React from "react";
import {useHeaderState} from "./headerContext";
import {ProgressIndicator} from "../app/ProgressIndicator";

export const WhenHeaderIsReady = ({children}) => {
    const headerState = useHeaderState();

    if(!headerState.rendered) {
        return <ProgressIndicator />
    }

    return (
        <>
            {children}
        </>
    )

}