import React, {useEffect, useState} from 'react';
import {CUSTOM, usePeriodDispatch, usePeriodState} from "./periodContext";
import {Button, Menu, MenuItem} from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import makeStyles from '@mui/styles/makeStyles';
import {RangePicker} from "./RangePicker";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    countQuarters,
    dateAsGoogleFormat,
    dateLocalized,
    formatYear,
    startOfTheYear,
    yearsAgo,
    yearsAgoAsGoogleFormat
} from "./dateFormatter";
import {useAppTranslation} from "../app/appContext";

const options = {
    today: {
        id: "today",
        label: "I dag",
        show: () => true
    },
    yesterday: {
        id: "yesterday",
        label: "I går",
        show: () => true
    },
    last7Days: {
        id: "last7Days",
        label: "Siste 7 dager",
        show: () => true
    },
    yearToDate: {
        id: "yearToDate",
        label: "Hittil i år",
        show: () => true
    },
    lastYear: {
        id: "lastYear",
        label: formatYear(yearsAgo(1)),
        show: () => true
    },
    firstQuarter: {
        id: "firstQuarter",
        label: "Q1",
        show: () => true
    },
    secondQuarter: {
        id: "secondQuarter",
        label: "Q2",
        show: () => 0 < countQuarters(new Date(), startOfTheYear(new Date()))
    },
    thirdQuarter: {
        id: "thirdQuarter",
        label: "Q3",
        show: () => 1 < countQuarters(new Date(), startOfTheYear(new Date()))
    },
    fourthQuarter: {
        id: "fourthQuarter",
        label: "Q4",
        show: () => 2 < countQuarters(new Date(), startOfTheYear(new Date()))
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(2)
    },
    menu: {
        display: "flex",
        flexDirection: "row"
    },
    menuItems: {
        display: "flex",
        flexDirection: "column"
    }
}))

export const DateSelector = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        id,
        startDate,
        endDate
    } = usePeriodState();
    const dispatch = usePeriodDispatch();
    const [showPicker, setShowPicker] = useState(false);
    const t = useAppTranslation();

    useEffect(() => {
        setShowPicker("custom" === id);
    }, [id])

    const currentSelected = options[id];

    const handleClose = () => {
        setAnchorEl(null);
    }

    const openMenu = event => setAnchorEl(event.currentTarget);

    const handleClick = id => {
        dispatch({type: `periodContext/${id}`})
        handleClose();
    }

    const handleCustomClick = () => {
        setShowPicker(true);
    }

    const customRangePicked = ({startDate, endDate}) => {
        dispatch({
            type: CUSTOM,
            endDate: `${dateAsGoogleFormat(endDate)}`,
            dimensions: 'nthMonth',
            orderBy: 'nthMonth',
            sort: 'ascending',
            startDate: `${dateAsGoogleFormat(startDate)}`,
            id: "last90Days"
        })
        handleClose()
    }

    const customLabel = () => `${dateLocalized(startDate.split(',')[0])} - ${dateLocalized(endDate.split(',')[0])}`

    return (
        <div className={classes.root}>
            <Button
                aria-controls="period-selector-menu"
                aria-haspopup="true"
                onClick={openMenu}
                endIcon={<DateRangeIcon />}
                variant={"outlined"}
                color={"primary"}
            >
                {currentSelected ? currentSelected.label : customLabel()}
            </Button>
            <Menu
                id="period-selector-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div className={classes.menu}>
                    <div className={classes.menuItems}>
                        {Object.keys(options).filter(optionKey => options[optionKey].show())
                            .map(optionKey => (
                            <MenuItem
                                key={optionKey}
                                onClick={() => handleClick(optionKey)}
                                selected={optionKey === id && !showPicker}
                            >
                                {"lastYear" === optionKey ? options[optionKey].label : t(optionKey, options[optionKey].label)}
                            </MenuItem>
                        ))}
                        <MenuItem
                            selected={"custom" === id || showPicker}
                            onClick={handleCustomClick}
                        >
                            {t('chooseCustomRange', "Velg til og fra dato")}
                            <NavigateNextIcon />
                        </MenuItem>
                    </div>
                    <RangePicker
                        startDate={"custom" === id ? startDate.split(',')[0] : null}
                        endDate={"custom" === id ? endDate.split(',')[0]: null}
                        open={showPicker}
                        onPick={customRangePicked}
                    />
                </div>
            </Menu>
        </div>
    )
}