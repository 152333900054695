import React from 'react';
import {UsersByObjectTypeDM} from "../object-types/UsersByObjectTypeDM";
import {Grid} from "@mui/material";
import {MetricsDM} from "../metrics/MetricsDM";
import {CountriesDM} from "../country/CountriesDM";

export const OverviewContent = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MetricsDM />
                </Grid>
                <Grid item xs={12} md={7}>
                    <UsersByObjectTypeDM />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CountriesDM />
                </Grid>
            </Grid>
        </>
  )
};