import React from 'react';
import {RoutesAuthenticated} from "./RoutesAuthenticated";

export const AppRoutes = () => {

    return (
        <>
            <RoutesAuthenticated />
        </>
    )
};