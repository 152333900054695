import React from 'react';
import {WithMyMuseums} from "./WithMyMuseums";

const MyMuseumsStateContext = React.createContext(undefined);
const MyMuseumsDispatchContext = React.createContext(undefined);

export const FILTER_BY_MUSEUM_IDS = 'filterByMuseumIds';

const initialState = {
    museumIdsFilter: []
}

const myMuseumsReducer = (state, action) => {
    switch (action.type) {
        case FILTER_BY_MUSEUM_IDS:
            return {
                ...state,
                museumIdsFilter: action.museumIds
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
}

export const MyMuseumsProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(myMuseumsReducer, {...initialState}, undefined);

    return (
        <MyMuseumsStateContext.Provider value={state}>
            <MyMuseumsDispatchContext.Provider value={dispatch}>
                <WithMyMuseums>
                    {children}
                </WithMyMuseums>
            </MyMuseumsDispatchContext.Provider>
        </MyMuseumsStateContext.Provider>
    )
}

export const useMyMuseumsState = () => {
    const context = React.useContext(MyMuseumsStateContext);
    if(undefined === context) {
        throw new Error("useMyMuseumsState must be used within a MyMuseumsProvider");
    } else {
        return context;
    }
}

export const useMyMuseumsDispatch = () => {
    const context = React.useContext(MyMuseumsDispatchContext);

    if(undefined === context) {
        throw new Error("useMyMuseumsDispatch must be used within a MyMuseumsProvider");
    } else {
        return context;
    }

}

