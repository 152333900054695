import {useAuthsState} from "./authsContext";
import {useAppState} from "../app/appContext";

export const useMyMuseumApps = () => {
    const {userData} = useAuthsState();
    const {apps, museums} = useAppState();

    return userData.appAccess.museums.map(myMuseum => ({
        ...myMuseum,
        ...museums.find(museum => museum.id === myMuseum.id),
        applications: myMuseum.applications.map(myApp => ({
            ...myApp,
            title: apps.find(app => app.id === myApp.id).title
        }))
    }))
}