import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box, Paper} from "@mui/material";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {parseDimension} from "../google-analytics/googleAnalyticsParser";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    trendingUp: {
        color: theme.palette.success.main
    },
    trendingDown: {
        color: theme.palette.error.main
    }
}))

const percentageChanged = (now, previous) => ((now - previous) / previous) * 100

const trendingIcon = (now, previous) => now > previous
    ? <TrendingUpIcon fontSize={"small"}/>
    : <TrendingDownIcon fontSize={"small"}/>;

const trendingClass = (now, previous, classes) => now >= previous ? classes.trendingUp : classes.trendingDown;


const tooltip = (period, dimension, label, previousPeriod) => {
    if (previousPeriod) {
        return `${parseDimension(period, dimension, label)} vs ${parseDimension(previousPeriod, dimension, label)}`
    } else {
        return parseDimension(period, dimension, label)
    }
}

const trend = (currentValue, previousValue, classes) => {
    if (previousValue) {
        return (
            <Box className={trendingClass(currentValue, previousValue, classes)}>
                {trendingIcon(currentValue, previousValue)}
                {percentageChanged(currentValue, previousValue).toFixed(2)}%
            </Box>
        )
    } else {
        return null
    }
}

export const MetricsTooltip = ({dimension, period, previousPeriod, label, payload}) => {
    const classes = useStyles();

    if (payload?.length) {
        const currentValue = parseInt(payload[0].value);
        const previousValue = parseInt(payload[1].value);
        return (
            <Paper className={classes.root} elevation={5}>
                <Box fontWeight={"fontWeightLight"}>
                    {tooltip(period, dimension, label, previousPeriod)}
                </Box>
                <Box fontWeight={"fontWeightBold"}>
                    {currentValue}
                </Box>
                {trend(currentValue, previousValue, classes)}
            </Paper>
        )
    } else {
        return null;
    }
}