import React from 'react';
import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {useAppTranslation} from "../app/appContext";
import {useTheme} from "@mui/material";

export const CountriesDMBar = ({data}) => {
    const t = useAppTranslation();
    const {palette} = useTheme();
    const {0: dataMax, [data.length - 1]: dataMin} = data

    if(!data.length) {
        return null;
    }

    return (
        <ResponsiveContainer
            width='97%'
            height={200}
            id="usersByObjectResponsive"
        >
            <BarChart
                layout="vertical"
                data={data}
            >
                <XAxis
                    type="number"
                    domain={[dataMin, dataMax]}
                    axisLine={false}
                    tickLine={false}
                    tick={false}
                    padding={{right: 100}}
                />
                <YAxis
                    dataKey="label"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                />
                <Bar dataKey='value' name={t('users', 'Brukere')} barSize={10} fill={palette.primary.main} label={{position: "right"}}/>
            </BarChart>
        </ResponsiveContainer>
    )
}