import React, {useEffect} from 'react';
import {SET_USER_DATA, useAuthsDispatch, useAuthsState} from "./authsContext";
import {kitFetch} from "@ekultur/fetch";

export const WithUserdata = ({children}) => {
    const authsDispatch = useAuthsDispatch();
    const {userData} = useAuthsState();

    useEffect(() => {
        kitFetch(`/authz/authorize/userData`)
            .then(json => {
                authsDispatch({
                    type: SET_USER_DATA,
                    userData: json
                })
            })
            .catch(error => {
                console.error(error)
            });
    }, [])


    if(Object.keys(userData).length) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}