import {useEffect, useState} from "react";
import {useDmOwners} from "./useDmOwners";
import {useMyMuseumsState} from "../../museum/myMuseumsContext";

export const useFilteredDmOwners = () => {
    const [filteredOwners, setFilteredOwners] = useState([]);
    const {museumIdsFilter} = useMyMuseumsState();
    const dmOwners = useDmOwners();

    useEffect(() => {
        if(museumIdsFilter.length) {
            setFilteredOwners(dmOwners.filter(owner => museumIdsFilter.includes(owner.id)));
        } else {
            setFilteredOwners(dmOwners);
        }
    }, [museumIdsFilter, dmOwners, setFilteredOwners]);

    return filteredOwners;
};