import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box, Button, Divider, Toolbar} from "@mui/material";
import {useAppTranslation} from "../app/appContext";
import {DatePicker} from "@mui/x-date-pickers";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    picker: {
        marginBottom: theme.spacing(2)
    },
    spacer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(2)
    }
}))

export const RangePicker = ({startDate, endDate, open, onPick}) => {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState(startDate);
    const [toDate, setToDate] = useState(endDate);
    const t = useAppTranslation();

    if(open) {
        return (
            <>
                <div className={classes.spacer}>
                    <Divider orientation={"vertical"} />
                </div>
                <div className={classes.root}>
                    <DatePicker
                        autoOk
                        // variant="inline"
                        value={fromDate}
                        onChange={setFromDate}
                        // InputProps={{variant: "contained"}}
                        views={["year", "month", "day"]}
                        className={classes.picker}
                        openTo={"year"}
                        label={t("fromDate", "Fra")}
                    />
                    <DatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        label={t("toDate", "Til")}
                        value={toDate}
                        onChange={setToDate}
                        views={["year", "month", "day"]}
                        className={classes.picker}
                        openTo={"year"}
                    />
                    <Toolbar>
                        <Box flexGrow={1} />
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => onPick({
                                startDate: fromDate,
                                endDate: toDate
                            })}
                        >
                            {t("useDateRageButton", "Bruk")}
                        </Button>
                    </Toolbar>
                </div>
            </>
        );
    } else {
        return null;
    }
}