import {PublishedObjectsPieChart} from "./PublishedObjectsPieChart";
import {PublishedObjectsBarChart} from "./PublishedObjectsBarChart";
import React from "react";
import {usePublishedObjects} from "./usePublishedObjects";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {PublishedObjectsTable} from "./PublishedObjectsTable";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import {useAppTranslation} from "../app/appContext";

const useStyles = makeStyles(() => ({
    grid: {
        flexGrow: 1
    }
}));

export const PublishedObjectsBody = () => {
    const report = usePublishedObjects();
    const classes = useStyles();
    const t = useAppTranslation();

    if(report.failed) {
        return (
            <div>
                {t('fetchFailed', "Klarte ikke å hente data.")}
            </div>
        )
    }

    if(!report.rows.length) {
        return (
            <ProgressIndicator/>
        )
    }

    return (
        <Grid className={classes.grid} container spacing={2}>
            <Grid item xs={12} md={6}>
                <PublishedObjectsPieChart published={report.published} notPublished={report.notPublished} />
            </Grid>
            <Grid item xs={12} md={6}>
                <PublishedObjectsBarChart rows={report.rows}/>
            </Grid>
            <Grid item xs={12} md={12}>
                <PublishedObjectsTable rows={report.rows} />
            </Grid>
        </Grid>
    )
};