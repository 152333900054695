import React, {useState} from 'react';
import {useDmOwners} from "../overview/digitaltmuseum/useDmOwners";
import useDeepCompareEffect, {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {INCLUDE_MUSEUM_IDS, OPEN, useMuseumFilterDispatch} from "@ekultur/museum-filter";
import {FILTER_BY_MUSEUM_IDS, useMyMuseumsDispatch} from "./myMuseumsContext";
import {useMediaQuery, useTheme} from "@mui/material";

export const WithMyMuseums = ({children}) => {
    const museumFilterDispatch = useMuseumFilterDispatch();
    const [museumFilterOpened, setMuseumFilterOpened] = useState(false);
    const myMuseumsDispatch = useMyMuseumsDispatch();
    const dmOwners = useDmOwners();
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('lg'));

    useDeepCompareEffect(() => {
        museumFilterDispatch({
            type: INCLUDE_MUSEUM_IDS,
            ids: dmOwners.map(owner => owner.id)
        });
        myMuseumsDispatch({
            type: FILTER_BY_MUSEUM_IDS,
            museumIds: dmOwners.map(owner => owner.id)
        });
    }, [dmOwners])

    useDeepCompareEffectNoCheck(() => {
        if(dmOwners.length && !mdDown && !museumFilterOpened) {
            setMuseumFilterOpened(true);
            museumFilterDispatch({type: OPEN});
        }
    }, [dmOwners, mdDown, museumFilterOpened, setMuseumFilterOpened])

    return (
        <>
            {children}
        </>
    )
}
