import {applyMiddleware, createStore, compose} from 'redux'
import thunk from 'redux-thunk'
import {rootReducer} from './rootReducer'


const middleware = [thunk];

export default function configureStore(preloadedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        rootReducer(),
        preloadedState,
        composeEnhancers(
            applyMiddleware(...middleware)
        )
    );
}
