import svCountries from 'localized-countries/data/sv'
import nbCountries from 'localized-countries/data/nb'
import nnCountries from 'localized-countries/data/nn'
import enCountries from 'localized-countries/data/en'
import localizedCountries from 'localized-countries';


const localizedCountry = () => {
    if('sv' === navigator.language){
        return svCountries;
    } else if('no' === navigator.language || 'nb' === navigator.language){
        return nbCountries;
    } else if('nn' === navigator.language){
        return nnCountries;
    } else {
        return enCountries;
    }
}

export const countryName = countryCode => {
    return localizedCountries(localizedCountry()).get(countryCode)
}