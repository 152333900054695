import {useAuthsState} from "./authsContext";

export const OnlyIfAuthenticated = ({children}) => {
    const {userIsAuthenticated} = useAuthsState();

    if (userIsAuthenticated) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}