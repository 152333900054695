import React from 'react';
import {Page} from "../layout/Page";
import {Header} from "../layout/Header";
import {OverviewContent} from "./OverviewContent";
import {Typography, useTheme} from "@mui/material";
import {useAppTranslation} from "../app/appContext";

export const Overview = () => {
    const t = useAppTranslation();
    const theme = useTheme()
    console.log(theme)

    return (
        <Page>
            <Header showMuseumFilter={true} showDateSelector={true}>
                <Typography variant={"h6"} component={"h1"}>
                    {t('overviewHeader' ,"Statistikk")}
                </Typography>
            </Header>
            <OverviewContent/>
        </Page>
    )
};