import {usePeriodState} from "../date/periodContext";
import {useFilteredDmOwners} from "../overview/digitaltmuseum/useFilteredDmOwners";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {kitFetch} from "@ekultur/fetch";
import {countryName} from "./countryNames";
import {useState} from "react";
import {useHasFilterModifications} from "../museum/useHasFilterModifications";
import {stringify} from "../querystring/querystring";

export const useCountries = () => {
    const {
        endDate,
        startDate,
        id
    } = usePeriodState();
    const [response, setResponse] = useState({
        loading: false,
        data: []
    });
    const owners = useFilteredDmOwners().map(owner => owner.id).join(',');
    const hasFilterModifications = useHasFilterModifications();


    useDeepCompareEffectNoCheck(() => {
        const url = '/dm-admin-api/v1/statistics/playground/reports/';
        if(owners && !hasFilterModifications) {
            setResponse(r => ({
                ...r,
                loading: true
            }))

            const parameters = {
                reportType: 0,
                endDate: endDate,
                metrics:'users',
                dimensions: `country,countryIsoCode`,
                orderBy: 'countryIsoCode',
                sort: 'descending',
                owner: owners,
                countryId: 'no',
                startDate: startDate,
                id: id,
                emptyRows: true
            };
            kitFetch(`${url}?${stringify(parameters)}`)
                .then(json => {
                    setResponse({
                        loading: false,
                        data: json.reports[0].data.rows.map(row => ({
                            id: row.dimensions[1].toLowerCase(),
                            label: countryName(row.dimensions[1]),
                            value: parseInt(row.metrics[0].values[0])
                        }))
                    })
                })
                .catch(error => {
                    console.error(error)
                    setResponse(r => ({
                        ...r,
                        loading: false
                    }))
                })
        }

    }, [owners, id, startDate, endDate, hasFilterModifications])

    return response;
}