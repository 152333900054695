import React, {useState} from 'react';
import {usePageViewsDM} from "./usePageViewsDM";
import Paper from "@mui/material/Paper";
import makeStyles from '@mui/styles/makeStyles';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Link from "@mui/material/Link";
import TableSortLabel from "@mui/material/TableSortLabel";
import {TableContainer} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {useTranslation} from "react-i18next";
import {WithProgressOverlay} from "../app/WithProgressOverlay";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10
    }
});

export const PageViewsTable = () => {
    const {t, ready} = useTranslation('googleAnalytics', {useSuspense: false});
    const [order, setOrder] = useState({by: 'uniquePageviews', descending: true});
    const [paging, setPaging] = useState({rows: 10, page: 0});
    const classes = useStyles();

    const pageViews = usePageViewsDM({
        orderBy: order.by,
        order: order.descending ? "desc" : "asc",
        start: paging.page * paging.rows,
        rows: paging.rows
    });

    const orderHandler = headerName => {
        if(headerName === order.by) {
            setOrder({
                ...order,
                descending: !order.descending
            })
        } else {
            setOrder({
                by: headerName,
                descending: true
            })
        }
    };

    const getDirection = headerName => {
        if(order.by === headerName) {
            return order.descending ? "desc": "asc"
        } else {
            return "desc"
        }
    };

    const changePageHandler = newPage => {
        setPaging({
            ...paging,
            page: newPage
        })
    };

    const changeRowsPerPageHandler = event => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0
        })
    };

    if(!ready || !pageViews.headers.length) {
        return <ProgressIndicator/>
    }

    return (
        <WithProgressOverlay loading={pageViews.loading}>
            <Paper className={classes.root} elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell key="emptyCell" />
                                {pageViews.headers.map(header => (
                                    <TableCell align="right" key={header}>
                                        <TableSortLabel
                                            active={order.by === header}
                                            direction={getDirection(header)}
                                            onClick={() => orderHandler(header)}
                                            disabled={pageViews.loading}
                                        >
                                        {t(header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell key="headerCell">
                                    Totalt:
                                </TableCell>
                                {pageViews.totals.map((total, index) => (
                                    <TableCell align="right" key={index}>
                                        {total}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageViews.rows.map(row => (
                                <TableRow key={row.header.id}>
                                    <TableCell component="th" scope="row" key="headerCell">
                                        <Link
                                            href={`https://digitaltmuseum.org/${row.header.id}`}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <Typography variant="subtitle2">
                                                {row.header.title}
                                            </Typography>
                                            {row.header.id}
                                        </Link>
                                    </TableCell>
                                    {row.cells.map((cell, index) => (
                                        <TableCell align="right" key={index}>
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={pageViews.rowCount}
                    rowsPerPage={paging.rows}
                    page={paging.page}
                    onPageChange={(event, newPage) => changePageHandler(newPage)}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                    backIconButtonProps={{disabled: pageViews.loading || paging.page === 0}}
                    nextIconButtonProps={{disabled: pageViews.loading || isLastPage(pageViews, paging)}}
                    SelectProps={{disabled: pageViews.loading}}
                    labelRowsPerPage="Rader per side:"
                    labelDisplayedRows={displaysRowsText}
                />
            </Paper>
        </WithProgressOverlay>
    );
};

const isLastPage = (pageViews, paging) => (Math.floor(pageViews.rowCount / paging.rows)) === paging.page;

const displaysRowsText = ({from, to, count}) => {
    const calculatedTo = to === -1 ? count : to;
    return `${from}-${calculatedTo} av ${count}`;
};