import {useFilteredDmOwners} from "../overview/digitaltmuseum/useFilteredDmOwners";
import {usePeriodState} from "../date/periodContext";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {kitFetch} from "@ekultur/fetch";
import {useState} from "react";
import {useHasFilterModifications} from "../museum/useHasFilterModifications";
import {stringify} from "../querystring/querystring";


export const useMetricsDM = () => {
    const [response, setResponse] = useState({
        loading: false,
        dimension: null,
        rows: [],
        maximums: [],
        dateRanges: [],
        totals: []
    })
    const url = '/dm-admin-api/v1/statistics/playground/reports/';
    const {
        endDate,
        dimensions,
        orderBy,
        sort,
        startDate,
        id
    } = usePeriodState();


    const owners = useFilteredDmOwners().map(owner => owner.id).join(',');
    const hasFilterModifications = useHasFilterModifications();

    useDeepCompareEffectNoCheck(() => {
        if(owners && !hasFilterModifications) {
            setResponse(r => ({
                ...r,
                loading: true
            }))
            const parameters = {
                reportType: 0,
                endDate: endDate,
                metrics: 'uniquePageviews,users,sessions',
                dimensions: dimensions,
                orderBy: orderBy,
                sort: sort,
                owner: owners,
                countryId: 'no',
                startDate: startDate,
                id: id,
                emptyRows: true
            };

            kitFetch(`${url}?${stringify(parameters)}`)
                .then(json => setResponse({
                    loading: false,
                    dimension: json.reports[0].columnHeader.dimensions[0],
                    rows: json.reports[0].data.rows,
                    maximums: json.reports[0].data.maximums,
                    dateRanges: json.reports[0].request.dateRanges,
                    totals: json.reports[0].data.totals
                }))
                .catch(error => {
                    console.error(error)
                    setResponse(r => ({
                        ...r,
                        loading: false
                    }))
                })
        }
    }, [owners, endDate, dimensions, orderBy, sort, startDate, id, hasFilterModifications])

    return response;
};