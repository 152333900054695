import {kitFetch} from "@ekultur/fetch";
import {stringify} from "../querystring/querystring";

export const REQUESTED = 'publishedObjects/requested';
export const RECEIVED = 'publishedObjects/received';
export const FAILED = 'publishedObjects/failed';

export const getPublishedObjects = ownerIds => dispatch => {
    const url = '/dm-admin-api/v1/statistics/primus_vs_dm/';

    const parameters = {
        owner: ownerIds
    };

    dispatch({type: REQUESTED, ownerIds: ownerIds});

    kitFetch(`${url}?${stringify(parameters)}`)
        .then(json => dispatch({
            type: RECEIVED,
            report: json.reports[0],
            ownerIds: ownerIds
        }))
        .catch(status => dispatch({
            type: FAILED,
            status: status,
            ownerIds: ownerIds
        }));
};