import React, {createContext, useContext, useReducer} from "react";
import {WithAuthentication} from "../auths/WithAuthentication";
import {CssBaseline} from "@mui/material";
import {HeaderProvider} from "../header/headerContext";
import {Header} from "../header/Header";
import {WhenHeaderIsReady} from "../header/WhenHeaderIsReady";
import {AuthsProvider} from "../auths/authsContext";
import {WithMuseums} from "../museum/WithMuseums";
import {WithApplications} from "../application/WithApplications";
import {PeriodProvider} from "../date/periodContext";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "./ProgressIndicator";

export const MUSEUMS_RECEIVED = 'appContext/museumsReceived';
export const APPS_RECEIVED = 'appContext/appsReceived';

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const initialState = {
    museums: [],
    apps: []
}

const appReducer = (state, action) => {
    console.debug(action);
    switch (action.type) {
        case MUSEUMS_RECEIVED:
            return {
                ...state,
                museums: action.museums
            }
        case APPS_RECEIVED:
            return {
                ...state,
                apps: action.apps
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(appReducer, {...initialState}, undefined);
    const {t, ready} = useTranslation('statsApp', {useSuspense: false});

    if(!ready) {
        return <ProgressIndicator />
    }

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                <AppTranslationContext.Provider value={t}>
                    <WithMuseums>
                        <WithApplications>
                            <AuthsProvider>
                                <WithAuthentication>
                                    <CssBaseline />
                                    <HeaderProvider>
                                        <Header />
                                        <WhenHeaderIsReady>
                                            <PeriodProvider>
                                                {children}
                                            </PeriodProvider>
                                        </WhenHeaderIsReady>
                                    </HeaderProvider>
                                </WithAuthentication>
                            </AuthsProvider>
                        </WithApplications>
                    </WithMuseums>
                </AppTranslationContext.Provider>
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if(undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
}

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if(undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
}

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if(undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider")
    } else {
        return context;
    }
}