import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import {useObjectTypesDM} from "./useObjectTypesDM";
import {ProgressIndicator} from "../app/ProgressIndicator";
import Paper from "@mui/material/Paper";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {WithProgressOverlay} from "../app/WithProgressOverlay";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10
    }
});

export const ObjectTypesTable = () => {
    const {t, ready} = useTranslation(['googleAnalytics', 'dm.objectTypes'], {useSuspense: false});
    const [order, setOrder] = useState({by: 'uniquePageviews', descending: true});
    const classes = useStyles();

    const pageViews = useObjectTypesDM({
        orderBy: order.by,
        order: order.descending ? "desc" : "asc",
        start: 0,
        rows: 50
    });

    const orderHandler = headerName => {
        if(headerName === order.by) {
            setOrder({
                ...order,
                descending: !order.descending
            })
        } else {
            setOrder({
                by: headerName,
                descending: true
            })
        }
    };

    const getDirection = headerName => {
        if(order.by === headerName) {
            return order.descending ? "desc": "asc"
        } else {
            return "desc"
        }
    };

    if(!ready || !pageViews.headers.length) {
        return <ProgressIndicator/>
    }

    return (
        <WithProgressOverlay loading={pageViews.loading}>
            <Paper className={classes.root} elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell key="emptyCell" />
                                {pageViews.headers.map(header => (
                                    <TableCell align="right" key={header}>
                                        <TableSortLabel
                                            active={order.by === header}
                                            direction={getDirection(header)}
                                            onClick={() => orderHandler(header)}
                                            disabled={pageViews.loading}
                                        >
                                            {t(header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell key="headerCell">
                                    Totalt:
                                </TableCell>
                                {pageViews.totals.map((total, index) => (
                                    <TableCell align="right" key={index}>
                                        {total}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageViews.rows.map(row => (
                                <TableRow key={row.header.title}>
                                    <TableCell component="th" scope="row" key="headerCell">
                                        <Link
                                            href={`https://digitaltmuseum.org/search/?aq=type:"${row.header.title}"`}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <Typography variant="subtitle2">
                                                {t(`dm.objectTypes:${row.header.title.toLowerCase()}`)}
                                            </Typography>
                                        </Link>
                                    </TableCell>
                                    {row.cells.map((cell, index) => (
                                        <TableCell align="right" key={index}>
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </WithProgressOverlay>
    )
};