import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom';
import {MENU_CLOSED, useHeaderDispatch, useHeaderState} from "../header/headerContext";
import ListSubheader from "@mui/material/ListSubheader";
import HomeIcon from '@mui/icons-material/Home';
import PublishIcon from '@mui/icons-material/Publish';
import PageviewIcon from '@mui/icons-material/Pageview';
import CategoryIcon from '@mui/icons-material/Category';
import {Hidden, Toolbar} from "@mui/material";
import {useAppTranslation} from "../app/appContext";
import {useAuthsState} from "../auths/authsContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
            zIndex: theme.zIndex.appBar - 1,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    drawerTitle: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export const Menu = () => {
    const classes = useStyles();
    const {menuOpen} = useHeaderState();
    const dispatch = useHeaderDispatch();
    const navigate = useNavigate()
    const t = useAppTranslation();
    const {userIsAuthenticated} = useAuthsState();

    const onMenuClick = url => {
        dispatch({type: MENU_CLOSED})
        navigate(url)
    }

    const home = (
        <List>
            <ListItem
                button={true}
                onClick={() => onMenuClick('/')}
            >
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.statistics', "Statistikk")} />
            </ListItem>
        </List>
    )

    const digitaltMuseum = (
        <List
            subheader={
                <ListSubheader component={"div"} id={"folders-subheader"}>
                    DigitaltMuseum
                </ListSubheader>
            }
        >
            <ListItem
                button={true}
                onClick={() => onMenuClick('/published-objects')}
            >
                <ListItemIcon>
                    <PublishIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.publishedObjects', "Publiserte objekter")} />
            </ListItem>
            <ListItem
                button={true}
                onClick={() => onMenuClick('/page-views')}
            >
                <ListItemIcon>
                    <PageviewIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.pageViews', "Sidevisninger")} />
            </ListItem>
            <ListItem
                button={true}
                onClick={() => onMenuClick('/object-types')}
            >
                <ListItemIcon>
                    <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary={t('menu.objectTypes', "Objekttyper")} />
            </ListItem>
        </List>
    )

    if(userIsAuthenticated) {
        return (
            <nav className={classes.drawer}>
                <Hidden mdUp implementation={"css"}>
                    <Drawer
                        open={menuOpen}
                        onClose={() => dispatch({type: MENU_CLOSED})}
                        PaperProps={{
                            elevation: 4
                        }}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerContainer}>
                            {home}
                            {digitaltMuseum}
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <div className={classes.drawerContainer}>
                            <Toolbar />
                            {home}
                            {digitaltMuseum}
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
        );
    } else {
        return null;
    }
}