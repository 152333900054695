import {useState} from "react";
import {useFilteredDmOwners} from "../overview/digitaltmuseum/useFilteredDmOwners";
import numeral from 'numeral';
import {kitFetch} from "@ekultur/fetch";
import {usePeriodState} from "../date/periodContext";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {useHasFilterModifications} from "../museum/useHasFilterModifications";
import {stringify} from "../querystring/querystring";

export const usePageViewsDM = ({orderBy, order, start, rows}) => {
    const [data, setData] = useState({headers: [], totals: [], rows: [], rowCount: 0, loading: false});
    const owners = useFilteredDmOwners().map(owner => owner.id).join(',');
    const {startDate, endDate} = usePeriodState();
    const hasFilterModifications = useHasFilterModifications();

    useDeepCompareEffectNoCheck(() => {
        if(owners && !hasFilterModifications) {
            setData(d => ({
                ...d,
                loading: true
            }));


            const parameters = {
                dimensions: 'dimension2,dimension3',
                emptyRows: false,
                startDate: startDate,
                endDate: endDate,
                metrics: 'uniquePageviews,users,avgTimeOnPage,bounceRate',
                orderBy: orderBy,
                owner: owners,
                reportType: 'pageViews',
                rows: rows,
                sort: order,
                start: start
            };

            const url = `/dm-admin-api/v1/statistics/playground/reports/?${stringify(parameters)}`;
            kitFetch(url)
                .then(json => {
                    const report = json.reports[0];
                    const headers = report.columnHeader.metricHeader.metricHeaderEntries;

                    if(report.data.rows) {
                        setData(buildData(headers, report))
                    } else {
                        setData(d => ({
                            headers: d.headers,
                            totals: [],
                            rows: [],
                            rowCount: 0,
                            loading: false
                        }))
                    }
                })
        }
    }, [orderBy, order, start, rows, owners, endDate, startDate, hasFilterModifications]);

    return data
};

const buildData = (headers, report) => ({
        headers: headers.map(header => header.name.split(':')[1]),
        totals: report.data.totals[0].values.map((value, index) => formatValue(value, headers[index].type)),
        rows: report.data.rows.map(row => ({
            header: {
                title: row.dimensions[0],
                id: row.dimensions[1]
            },
            cells: row.metrics[0].values.map((value, index) => formatValue(value, headers[index].type))
        })),
        rowCount: report.data.rowCount,
        loading: false
});

const formatValue = (value, type) => {
    switch(type) {
        case 'TIME':
            return numeral(value).format('00:00:00');
        case 'PERCENT':
            return numeral(value/100).format('0.00%');
        default:
            return value
    }
};