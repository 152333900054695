import React from 'react';
import {Page} from "../layout/Page";
import {Header} from "../layout/Header";
import {PublishedObjectsBody} from "./PublishedObjectsBody";
import {Typography} from "@mui/material";
import {useAppTranslation} from "../app/appContext";

export const PublishedObjects = () => {
    const t = useAppTranslation();
    return (
        <Page>
            <Header showMuseumFilter={true}>
                <Typography variant={"h6"} component={"h1"}>
                    {t('primusVsDigitaltMusuem', 'Primus objekter publisert på DigitaltMuseum')}
                </Typography>
            </Header>
            <PublishedObjectsBody />
        </Page>
    )
};