import React from "react";
import {Page} from "../layout/Page";
import {Header} from "../layout/Header";
import {ObjectTypesTable} from "./ObjectTypesTable";
import {Typography} from "@mui/material";
import {useAppTranslation} from "../app/appContext";

export const ObjectTypes = () => {
    const t = useAppTranslation();
    return (
        <Page >
            <Header showMuseumFilter={true} showDateSelector={true}>
                <Typography variant={"h6"} component={"h1"}>
                    {t('objectTypes', 'Objekttyper')}
                </Typography>
            </Header>
            <ObjectTypesTable/>
        </Page>
    )
};