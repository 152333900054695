import React, {createContext, useContext, useReducer} from "react";
import {
    asGoogleFormat,
    endOfTheQuarter,
    endOfTheYear,
    rangeAsGoogleFormat,
    startOfTheQuarter,
    startOfTheYear,
    yearsAgo
} from "./dateFormatter";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

export const TODAY = "periodContext/today";
export const YESTERDAY = "periodContext/yesterday";
export const LAST7DAYS = "periodContext/last7Days"
export const YEARTODATE = "periodContext/yearToDate";
export const LASTYEAR = "periodContext/lastYear";
export const CUSTOM = "periodContext/custom";
export const Q1 = 'periodContext/firstQuarter';
export const Q2 = 'periodContext/secondQuarter';
export const Q3 = 'periodContext/thirdQuarter';
export const Q4 = 'periodContext/fourthQuarter';

const PeriodStateContext = createContext(undefined);
const PeriodDispatchContext = createContext(undefined);

const initialState = {
    endDate: 'yesterday,8daysAgo',
    dimensions: 'nthDay',
    orderBy: 'nthDay',
    sort: 'ascending',
    startDate: '7daysAgo,14daysAgo',
    id: "last7Days"
}

const periodReducer = (state, action) => {
    console.debug(action);
    switch (action.type) {
        case TODAY:
            return {
                endDate: 'today',
                dimensions: 'nthHour',
                orderBy: 'nthHour',
                sort: 'ascending',
                startDate: 'today',
                id: "today"
            };
        case YESTERDAY:
            return {
                endDate: 'yesterday',
                dimensions: 'nthHour',
                orderBy: 'nthHour',
                sort: 'ascending',
                startDate: 'yesterday',
                id: "yesterday"
            };
        case LAST7DAYS:
            return {
                endDate: 'yesterday',
                dimensions: 'nthDay',
                orderBy: 'nthDay',
                sort: 'ascending',
                startDate: '7daysAgo',
                id: "last7Days"
            };
        case YEARTODATE:
            return {
                endDate: asGoogleFormat(endOfTheYear()),
                dimensions: 'nthMonth',
                orderBy: 'nthMonth',
                sort: 'ascending',
                startDate: asGoogleFormat(startOfTheYear()),
                id: "yearToDate"
            }
        case LASTYEAR:
            return {
                endDate: asGoogleFormat(endOfTheYear(yearsAgo(1))),
                dimensions: 'nthMonth',
                orderBy: 'nthMonth',
                sort: 'ascending',
                startDate: asGoogleFormat(startOfTheYear(yearsAgo(1))),
                id: "lastYear"
            }
        case CUSTOM:
            return {
                endDate: action.endDate,
                dimensions: action.dimensions,
                orderBy: action.orderBy,
                sort: action.sort,
                startDate: action.startDate,
                id: "custom"
            }
        case Q1:
            return {
                endDate: asGoogleFormat(endOfTheQuarter({quarter: 1})),
                dimensions: 'nthWeek',
                orderBy: 'nthWeek',
                sort: 'ascending',
                startDate: asGoogleFormat(startOfTheQuarter({quarter: 1})),
                id: "firstQuarter"
            }
        case Q2:
            return {
                endDate: asGoogleFormat(endOfTheQuarter({quarter: 2})),
                dimensions: 'nthWeek',
                orderBy: 'nthWeek',
                sort: 'ascending',
                startDate: asGoogleFormat(startOfTheQuarter({quarter: 2})),
                id: "secondQuarter"
            }
        case Q3:
            return {
                endDate: asGoogleFormat(endOfTheQuarter({quarter: 3})),
                dimensions: 'nthWeek',
                orderBy: 'nthWeek',
                sort: 'ascending',
                startDate: asGoogleFormat(startOfTheQuarter({quarter: 3})),
                id: "thirdQuarter"
            }
        case Q4:
            return {
                endDate: asGoogleFormat(endOfTheQuarter({quarter: 4})),
                dimensions: 'nthWeek',
                orderBy: 'nthWeek',
                sort: 'ascending',
                startDate: asGoogleFormat(startOfTheQuarter({quarter: 4})),
                id: "fourthQuarter"
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const PeriodProvider = ({children}) => {
    const [state, dispatch] = useReducer(periodReducer, {...initialState}, undefined);

    return (
        <PeriodStateContext.Provider value={state}>
            <PeriodDispatchContext.Provider value={dispatch}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {children}
                </LocalizationProvider>
            </PeriodDispatchContext.Provider>
        </PeriodStateContext.Provider>
    )
}

export const usePeriodState = () => {
    const context = useContext(PeriodStateContext);
    if(undefined === context) {
        throw new Error(`usePeriodState must be used witin a PeriodProvider`);
    } else {
        return context;
    }
}

export const usePeriodDispatch = () => {
    const context = useContext(PeriodDispatchContext);
    if(undefined === context) {
        throw new Error(`usePeriodDispatch must be used within a PeriodProvider`);
    } else {
        return context;
    }
}