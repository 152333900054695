import React from 'react';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {useTranslation} from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useAppTranslation} from "../app/appContext";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10
    }
});

export const PublishedObjectsTable = ({rows}) => {
    const classes = useStyles();
    const {t, ready} = useTranslation('dm.objectTypes', {useSuspense: false});
    const appT = useAppTranslation();

    if(!ready) {
        return <ProgressIndicator/>
    }

    const translatedRows = rows.map(row => ({
        ...row,
        name: t(row.name.toLowerCase())
    }));


    return (
        <Paper className={classes.root} elevation={3}>
            <Typography variant="h6" align='center'>
                {appT('splitByObjectType', "Fordelt på objekttype")}
            </Typography>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {appT("objectType", "Objekttype")}
                        </TableCell>
                        <TableCell align="right">
                            {appT("registeredInPrimus", "Registrert i primus")}
                        </TableCell>
                        <TableCell align="right">
                            {appT("published", "Publiserte")}
                        </TableCell>
                        <TableCell align="right">
                            {appT("notPublished", "Ikke publiserte")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {translatedRows.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">
                                {row.primus}
                            </TableCell>
                            <TableCell align="right">
                                {row.published}
                            </TableCell>
                            <TableCell align="right">
                                {row.notPublished}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
};