import {useAuthsState} from "./authsContext";
import {useAppState} from "../app/appContext";

export const useMyApps = () => {
    const {userData} = useAuthsState();
    const {apps} = useAppState();

    return userData.appAccess.apps.map(myApp => ({
        ...myApp,
        title: apps.find(app => app.id === myApp.id).title
    }))
}