import {useMatch} from "react-router";
import {PublishedObjects} from "./PublishedObjects";

export const PublishedObjectsRoute = () => {
    const match = useMatch('/published-objects')

    if(match) {
        return (
            <PublishedObjects />
        )
    } else {
        return null
    }

}