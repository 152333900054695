import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {AppRoutes} from "./AppRoutes";
import {Theme} from "./Theme";
import {AppProvider} from "./appContext";
import {Menu} from "../menu/Menu";
import {Content} from "./Content";
import {MuseumFilterProvider} from '@ekultur/museum-filter';
import {Callback} from "../auths/Callback";
import {OnlyIfAuthenticated} from "../auths/OnlyIfAuthenticated";
import {Box} from "@mui/material";


export const App = () => {
    if (!window._env_?.REACT_APP_API_GATEWAY) {
        return null;
    }

    return (
        <Theme>
            <Router>
                <AppProvider>
                    <Callback/>
                    <OnlyIfAuthenticated>
                        <Box
                            sx={{
                                display: "flex"
                            }}
                        >
                            <Menu/>
                            <MuseumFilterProvider>
                                <Content>
                                    <AppRoutes/>
                                </Content>
                            </MuseumFilterProvider>
                        </Box>
                    </OnlyIfAuthenticated>
                </AppProvider>
            </Router>
        </Theme>
    )
};