import {useMatch} from "react-router";
import {ObjectTypes} from "./ObjectTypes";

export const ObjectTypesRoute = () => {
    const match = useMatch('/object-types')

    if(match) {
        return (
            <ObjectTypes />
        )
    } else {
        return null
    }

}