import {daysAgo, onlyHours, today, withAddedDays, withAddedMonths, withAddedWeeks} from "../date/dateFormatter";

export const parseDate = gaDate => {
    const gaDateLowered = gaDate.toLowerCase();

    if(gaDateLowered.includes('daysago')) {
        return daysAgo(parseInt(gaDateLowered));
    } else if (gaDateLowered.includes('yesterday')) {
        return daysAgo(1);
    } else if (gaDateLowered === "today") {
        return today();
    } else {
        return gaDate;
    }
}

export const parseDimension = (period, dimension, value) => {
    switch (dimension) {
        case "ga:nthHour":
            return onlyHours(value)
        case "ga:nthDay":
            return withAddedDays(period.startDate, value)
        case "ga:nthMonth":
            return withAddedMonths(period.startDate, value)
        case "ga:nthWeek":
            return withAddedWeeks(period.startDate, value)
        default:
            return value
    }
}