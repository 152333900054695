import React from 'react';
import {Box} from "@mui/material";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import makeStyles from '@mui/styles/makeStyles';

const percentageChanged = (now, previous) => ((now - previous) / previous) * 100
const trendingIcon = (now, previous) => now >= previous
    ? <TrendingUpIcon fontSize={"small"} />
    : <TrendingDownIcon fontSize={"small"} />;

const trendingClass = (now, previous, classes) => now >= previous ? classes.trendingUp : classes.trendingDown

const useStyles = makeStyles(theme => ({
    trendingUp: {
        color: theme.palette.success.main
    },
    trendingDown: {
        color: theme.palette.error.main
    }
}))

export const TabLabel = ({primaryText, totals, previousTotals}) => {
    const classes = useStyles();
    const intTotals = parseInt(totals);
    const intPreviousTotals = parseInt(previousTotals);

    const getTrend = () => {
        if(!totals || !previousTotals) {
            return null;
        } else {
            return (
                <Box className={trendingClass(intTotals, intPreviousTotals, classes)}>
                    {trendingIcon(intTotals, intPreviousTotals)}
                    {percentageChanged(intTotals, intPreviousTotals).toFixed(2)}%
                </Box>
            )
        }
    }

    return (
        <>
            <Box fontWeight={"fontWeightLight"}>
                {primaryText}
            </Box>
            <Box fontWeight={"fontWeightBold"}>
                {totals}
            </Box>
            {getTrend()}
        </>
    )
}