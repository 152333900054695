import {FAILED, RECEIVED, REQUESTED} from "./publishedObjectsAction";

const initialState = {
    reports: {}
};

export const publishedObjectsDm = (state=initialState, action) => {
    switch(action.type) {
        case REQUESTED:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.ownerIds]: {
                        rows: [],
                        published: 0,
                        notPublished: 0,
                        dataMin: 0,
                        dataMax: 0,
                        failed: false
                    }
                }
            };
        case RECEIVED:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.ownerIds]: {
                        published: parseInt(action.report.data.totals[0].values[0]),
                        notPublished: parseInt(action.report.data.totals[0].values[1]),
                        dataMin: parseInt(action.report.data.minimums[0].values[2]),
                        dataMax: parseInt(action.report.data.maximums[0].values[2]),
                        rows: action.report.data.rows.map(row => ({
                            name: row.dimensions[0],
                            published: parseInt(row.metrics[0].values[0]),
                            notPublished: parseInt(row.metrics[0].values[1]),
                            primus: parseInt(row.metrics[0].values[2])
                        })).filter(row => 0 < (row.published + row.notPublished)),
                        failed: false
                    }
                }
            };
        case FAILED:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.ownerIds]: {
                        failed: true,
                        failTime: new Date().getTime()
                    }
                }
            };
        default:
            return state
    }
};