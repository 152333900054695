import React from 'react';
import {WithUserdata} from "../auths/WithUserdata";
import {MyMuseumsProvider} from "../museum/myMuseumsContext";
import {OverviewRoute} from "../overview/OverviewRoute";
import {PublishedObjectsRoute} from "../published-objects/PublishedObjectsRoute";
import {PageViewsRoute} from "../page-views/PageViewsRoute";
import {ObjectTypesRoute} from "../object-types/ObjectTypesRoute";

export const RoutesAuthenticated = () => {
    return (
        <WithUserdata>
            <MyMuseumsProvider>
                <OverviewRoute/>
                <PublishedObjectsRoute/>
                <PageViewsRoute/>
                <ObjectTypesRoute/>
            </MyMuseumsProvider>
        </WithUserdata>
    )
}