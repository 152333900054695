import {useState} from 'react';
import {useAppState} from "../../app/appContext";
import {useMyApps} from "../../auths/useMyApps";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useMyMuseumApps} from "../../auths/useMyMuseumApps";

export const useDmOwners = () => {
    const [dmOwners, setDmOwners] = useState([]);
    const {museums} = useAppState();
    const apps = useMyApps();
    const myMuseums = useMyMuseumApps();

    useDeepCompareEffect(() => {
        const isDmAdmin = apps.find(app => app.title === 'DigitaltMuseum' && app.role.name === 'Admin');
        const isDepartment = myMuseums.find(museum => museum.type === 'department');

        if(isDepartment) {
            setDmOwners(museums
                .filter(museum => museum.share_statistics)
                .map(museum => ({
                    ...museum,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)))
        } else if(isDmAdmin) {
            setDmOwners(museums
                .map(museum => ({
                    ...museum,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)))
        } else {
            const ownersWithDm = myMuseums
                .filter(museum => museum.applications
                    .filter(app => app.title === "DigitaltMuseum").length)
                .sort((a, b) => a.name.localeCompare(b.name));

            setDmOwners(ownersWithDm);
        }

    }, [myMuseums, setDmOwners, apps, museums]);

    return dmOwners
};